import { AppSignature } from "src/app/models/app-signature";

export class PermissionTree {
    name: string;
    permission: string;
    id: number;
    pid: number;
    hasChild: boolean;
    hideChild = true;
    checked = false;
}

export class RolePermissionMap extends AppSignature {
    permission: string;
    parentNotebookUserRoleID: string;
}

export class UserRolesPermissionMap extends AppSignature {
    userID: string;
    parentNotebookUserRoleID: string;
}

export class UserRolesPermissionMapDTO { 
    userRolesPermissionMap:UserRolesPermissionMap = new UserRolesPermissionMap();
}

export class PermissionPostData {
    notebookUserRole: NotebookUserRoles;
    permissions: Array<string> = [];
}

export class NotebookUserRoles extends AppSignature {
    name: string;
}

export class RoleDTO {
    notebookUserRole: NotebookUserRoles;
    rolePermissions: Array<RolePermissionMap> = [];
}