import { AppSignature } from 'src/app/models/app-signature';
import { AddressModel } from 'src/app/models/address.model';
import { FileTransferModel } from 'src/app/models/file-transfer';
import { Board } from 'src/app/models/master.model';
import { GradeModel } from '../../../models/master.model';
import { SubscriptionDTO } from '../../institute-subscription/models/subscription.model';
import { WatchedVideoModel } from '../../resource/models/video.model';

export class InstituteModel extends AppSignature {
  name: string;
  code: string;
  email: string;
  phone: string;
  contactPersonName: string;
  contactPersonDesignation: string;
  fax: string;
  websiteUrl: string;
  profileImage: string;
  registrationNumber: string;
  registrationDate: string;
  businessModelType: number;
  status: number;
  isActive: boolean;
  applicationMode: number;
  alternateEmail: string;
  alternatePhone: string;
  parentAddress: AddressModel = new AddressModel();
  instituteSource: InstituteSource;
  parentReferrerID:string;
}
export class InstituteDTO {
  institute: InstituteModel = new InstituteModel();
  boards: Array<Board> = new Array<Board>();
  grade: Array<GradeModel> = new Array<GradeModel>();
  profileImage: FileTransferModel = new FileTransferModel();
  hasSubscription: boolean;
  usernameValid = true;
}

export class InstituteWatchedVideoDTO extends InstituteDTO {
  watchedVideo: Array<WatchedVideoModel> = [];
}

export class InstituteView extends InstituteDTO {
  subscriptions: Array<SubscriptionDTO> = [];
}

export class BoardGrades {
  board: Board;
  grades: Array<GradeModel> = [];
}

export class BoardGradeMap {
  board: Board;
  grade: GradeModel;
  paid: boolean = false;
}

export class ClassMap {
  board: Board;
  class: Array<BoardGradeMap> = [];
}

export class InstituteDiscountModel extends AppSignature {
  isActive: boolean;
  parentInstituteID: string;
  discount: number;
  discountType: number;
}

export class InstituteDiscountDTO {
  instituteDiscount: InstituteDiscountModel = new InstituteDiscountModel();
}

export enum InstituteSource {
  NA = 0,
  BrainBoxSchool = 1
}

