import { AppSignature } from 'src/app/models/app-signature';
import { AddressModel } from 'src/app/models/address.model';
import { Agent } from 'http';
import { UserRolesPermissionMap } from '../../notebook-user-roles/models/notebook-roles';


export class AgentModel extends AppSignature {
  name: string;
  email: string;
  phone: string;
  profilePicture: string;
  isActive: boolean;
  userNameType: number = 0;
  agentID: string;
  parentAgentReportingManagerID: string;
  applicationMode: number;
  agentType: number;
  profileImage: string;
  useThirdPartyPayment: boolean;
  accountSecret: string;
  accountKey: string;
  paymentGatewayProvider: number;
  websiteUrl: string;
  brandName: string;
  logoUrl: string;
  altLogoUrl: string;
  iconUrl: string;
  altIconUrl: string;
  paymentGatewayUrl: string;
  useAgentBranding: boolean;
  allowSendingPaymentLink: boolean;
}

export class AgentCommissionModel extends AppSignature {
  parentAgentID: string;
  commission: number;
  commissionType: number = 1;
  discount: number;
  discountType: number = 1;
  isActive: boolean;
  prorateType: number = 0;
  useAgentProrate: boolean;
}

export class agentCommissionDTO {
  agentCommission: AgentCommissionModel = new AgentCommissionModel();
}

export class AgentDTO {
  agent: AgentModel = new AgentModel();
  agentCommission: AgentCommissionModel = new AgentCommissionModel();
  loginRecord: any;
  role: any;
  address: AddressModel = new AddressModel();
  username: string = '';
  rolesPermissionMap: UserRolesPermissionMap = new UserRolesPermissionMap();
}

export class user {
  username: string;
  password: string;
  isForcedPasswordResetReqiuired: boolean;
}

export class AgentPostModel extends AgentDTO {
  user: user = new user();
  sendWeclomeMessage: boolean;
}

export class AgentCredit extends AppSignature {
  amount: number;
  parentUserID: string;
  creditType: number;
  remarks: String;
  agentType: number;

}

export class AgentCreditClaim extends AppSignature {
  amount: number;
  currencyCode: string;
  parentUserID: string;
  claimMode: number;
  reference: string;
  status: number
  creditType: number
  remarks: string;
}
export class AgentCreditReportDto {
  agentCommissionCredits: Array<AgentCredit>;;
  totalCreditAmount: number;
  currencyCode: string;
  creditType: number;
  agent: AgentModel = new AgentModel();
  agentCommissionCreditClaim: AgentCreditClaim = new AgentCreditClaim();
}

export class AgentCreditClaimPostModel {
  agentCreditClaim: AgentCreditClaim = new AgentCreditClaim();
  agentCreditIdList: Array<string> = new Array<string>();
  notify: boolean = false;
}

export enum BookingByAgentType {
  NA = 0,
  CommissionDiscount = 1
}

export class AgentView {
  id: string;
  agentID: string;
  brandName: string;
  logoUrl: string;
  altLogoUrl: string;
  iconUrl: string;
  altIconUrl: string;
  useAgentBranding: boolean;
}
