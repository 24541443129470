import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  standalone: false,
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  template: '<router-outlet></router-outlet>',
  styles: [':host {height: 100%;margin: 0;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    // fetch("https://ipapi.co/json/")
    //   .then(res => res.json())
    //   .then(response => {
    //     if (response.country_name == "India" && window.location.origin != environment.webUrlLocal) {
    //       window.open(environment.webUrlLocal + window.location.pathname, "_self")
    //     }
    //     if (response.country_name != "India" && window.location.origin != environment.webUrlInternational) {
    //       window.open(environment.webUrlInternational + window.location.pathname, "_self")
    //     }
    //   });
  }
}
