import { AuthToken } from "../modules/auth/models/auth.token";
import { AssessmentFilter, LessonDTO, LessonFilter, ReferenceContentDTO, TopicAndContent } from "../modules/class/models/lesson.model";

export class LocalData {
     authToken: AuthToken | undefined = undefined;
     authTokenExpiry: Date = new Date();
}
export class UserConfig {
     skipProfileUpdate: boolean = false;
}

export class GenericData {
     message: string | undefined = undefined;
     lessonContent: Array<TopicAndContent> | undefined = undefined;
     lesson: LessonDTO | undefined = undefined;
     lessonFilter: LessonFilter | undefined = undefined;
     userConfig: UserConfig | undefined = undefined;
     refContent: Array<ReferenceContentDTO> | undefined = undefined;
     assessmentFilter:AssessmentFilter | undefined = undefined;
}