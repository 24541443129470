import { AppSignature } from "./app-signature";

export class AddressModel extends AppSignature{
  addressLine1: string;
  addressLine2: string;
  landMark:string;
  city: string;
  state: string;
  country:string;
  pincode: string = '000000';
} 
